import { Form, SubmissionError } from 'dataformjs';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import AuthBox from '../../../components/base/AuthBox';
import FullPageCenter from '../../../components/base/FullPageCenter';
import Title from '../../../components/base/Title';
import App from '../../../components/layout/App';
import { administratorCreate as createMutation } from '../../../objects/administrators/mutations';
import { font } from '../../../styles/theme';
import datas from './create.json';

const CreateAccount: FC = () => {
  const { t } = useTranslation();
  const [createMutationFunc] = useMutation(createMutation);

  const onSubmit = async (values: any) => {
    try {
      const result = await createMutationFunc({
        variables: values,
      });

      if (!result || result.errors) {
        console.error('error', result?.errors);
      }
      console.info('result', result);
    } catch (error) {
      if (error instanceof Error) {
        throw new SubmissionError({ _error: error.message });
      } else {
        throw new SubmissionError({ _error: error });
      }
    }
  };

  return (
    <App naked>
      <FullPageCenter>
        <AuthBox standard>
          <div className="w-full">
            <Title className="mb-6" size={font.size.xxl[0]}>
              {t('account.admin.creation.title')}
            </Title>
            <Form
              className="w-full"
              datas={datas}
              id="createAccount"
              name="admin-account-create"
              onSubmit={onSubmit}
              submitProps={{ label: 'account.create' }}
            />
          </div>
        </AuthBox>
      </FullPageCenter>
    </App>
  );
};

export default CreateAccount;
