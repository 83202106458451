import gql from 'graphql-tag';

const administratorsCreateAPIKey = gql`
  mutation organizationUsersCreateAPIKey($id: String!) {
    organizationUsersCreateAPIKey(id: $id)
  }
`;
const administratorCreate = gql`
  mutation organizationUsersCreate(
    $email: String!
    $firstName: String!
    $lastName: String!
    $organizationId: String!
    $password: String!
    $role: String!
  ) {
    organizationUsersCreate(
      email: $email
      firstName: $firstName
      lastName: $lastName
      organizationId: $organizationId
      password: $password
      role: $role
    ) {
      id
    }
  }
`;

const administratorsUpdate = gql`
  mutation organizationUsersUpdate($data: JSONObject, $id: GUID!) {
    organizationUsersUpdate(data: $data, id: $id) {
      id
    }
  }
`;

export {
  administratorCreate,
  administratorsCreateAPIKey,
  administratorsUpdate,
};
